import workplaceTypeLabel from 'src/utils/workplace';
import contractTypeLabel from 'src/utils/contract-types';
import workloadTypeLabel from 'src/utils/workload-types';

export const ABORT_REASONS = {
  'no active subscription': {
    label: 'Unknown',
    info: () => 'You need an active subscription to enable matchings',
    color: (theme) => theme.palette.warning.main,
  },
  'contract type not allowed': {
    label: 'Ignored',
    info: (info) =>
      info?.contractType
        ? `You have excluded ${contractTypeLabel(info?.contractType)} postings from your matchings`
        : `This project does not match your preferences for contract types`,
    color: (theme) => theme.palette.grey[500],
  },
  'workload not allowed': {
    label: 'Ignored',
    info: (info) =>
      info?.workload
        ? `You have excluded ${workloadTypeLabel(info?.workload)} postings from your matchings`
        : `This project does not match your preferences for workload`,
    color: (theme) => theme.palette.grey[500],
  },
  'work location not allowed': {
    label: 'Ignored',
    info: (info) =>
      info?.workLocation
        ? `You have excluded ${workplaceTypeLabel(info?.workLocation)} postings from your matchings`
        : `This project does not match your preferences for work locations`,
    color: (theme) => theme.palette.grey[500],
  },
  'user did not set home location': {
    label: 'Error',
    info: () => 'You need to set your home location to enable matchings',
    color: (theme) => theme.palette.danger.main,
  },
  'posting too far away by work location filter': {
    label: 'Ignored',
    info: (info) =>
      info?.distance && info?.allowedDistance && info?.workLocation
        ? `This posting (${workplaceTypeLabel(
            info?.workLocation
          )}) is too far away from your home location (${info?.distance} km)`
        : `This project is too far away from your location`,
    color: (theme) => theme.palette.grey[500],
  },
};
